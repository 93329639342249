import React from 'react'
import { FormattedMessage } from 'react-intl'
import BlueImg from './assets/images/blue.svg'
import GreenImg from './assets/images/green.svg'
import PinkImg from './assets/images/pink.svg'

import './styles.scss'

const CashBenefits: React.FC = () => {

  const cards = [
    {
      title: 'cash_landing.benefits.card1.title',
      description: 'cash_landing.benefits.card1.description',
      image: BlueImg,
    },
    {
      title: 'cash_landing.benefits.card2.title',
      description: 'cash_landing.benefits.card2.description',
      image: PinkImg,
    },
    {
      title: 'cash_landing.benefits.card3.title',
      description: 'cash_landing.benefits.card3.description',
      image: GreenImg,
    }
  ]

  return (
    <div className="container">
      <div className="cash-benefits">
        <h2 className="text-md-left text-xs-center text-primary"><FormattedMessage id="cash_landing.benefits.title"  /> </h2>
        <div className="row cash-cards-benefits">
          {
            cards.map((card, index) => (
              <div className="col-lg-4 col-md-2 col-12" key={index}>
                <div className="card card-benefits">
                  <div className="card-benefits__icons">
                    <img className="card-icon-benefits" src={card.image} alt={card.title} />
                  </div>
                  <div className="card-benefits-body">
                    <h6 className="card-title-benefits text-primary">
                      <FormattedMessage id={card.title} />
                    </h6>
                    <p className="card-text">
                      <FormattedMessage id={card.description} />
                    </p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CashBenefits