import React from 'react'
import { FormattedMessage } from 'react-intl'
import CashCarrouselCard from './CashCarrouselCard'
import './styles.scss'

const CashCarrousel:React.FC = () => {
  return (
    <>
      <div className="cash-carrousel mt-7">
        <div className="container text-center">
          <h2 className="cash-carrousel-title"><FormattedMessage id='cash_landing.carrousel.title' /> </h2>
          <p className="cash-carrousel-desc"><FormattedMessage id="cash_landing.carrousel.description" /></p>
          <div className="row cash-carrousel-container">
            <CashCarrouselCard />
          </div>
        </div>
      </div>
    </>
  )
}

export default CashCarrousel