import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat';
import './styles.scss'

interface CashPointsProps {
  country: string
  mapData: string
}

const CashPoints: React.FC<CashPointsProps> = ({country, mapData}) => {
  const countrySelector = () => {
    if(country === 'colombia' ){
      return (
        <div className='cash-country'>
          <h4 className='cash-country-title'>Colombia</h4>
          <h2 className='cash-country-points'>+95,000</h2>
        </div> 
      )
    }
    else if(country  === 'ecuador'){
      return(
        <div className='cash-country'>
          <h4 className='cash-country-title'>Ecuador</h4>
          <h2 className='cash-country-points'>+30,000</h2>
        </div>
      )
    } else if(country  === 'chile'){
      return (
        <div className='cash-country'>
          <h4 className='cash-country-title'>Chile</h4>
          <h2 className='cash-country-points'>+4,000</h2>
        </div> 
      )
    
    } else if(country  === 'peru' ){
      return (
        <div className='cash-country'>
          <h4 className='cash-country-title'>Perú</h4>
          <h2 className='cash-country-points'>+40,000</h2>
        </div> 
      )
    } 
    else if(country  === 'mexico'){
      return (
        <div className='cash-country-na'>
          <h4 className='cash-country-title'>México</h4>
          <h2 className='cash-country-points'>+25,000</h2>
        </div> 
      )
    } else {
      return (
        <div>
          
        </div> 
      )
    }
  }
  return (
    <>
      {countrySelector()}  
    </>
  )
}

export default CashPoints