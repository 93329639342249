import React, {useState} from 'react'

import colombiaCircle from './assets/images/colombia.png'
import ecuadorCircle from './assets/images/ecuador.png'
import peruCircle from './assets/images/peru.png'
import chileCircle from './assets/images/chile.png'
import mexCircle from './assets/images/mexico.png'


interface AccordionCardsProps{
  mapData: string
}

const AccordionCards: React.FC<AccordionCardsProps>= ({mapData = 'southAmerica'}) => {

  // const [isOpen1, setIsOpen1] = useState(true)
  // const [isOpen2, setIsOpen2] = useState(false)
  // const [isOpen3, setIsOpen3] = useState(false)
  // const [isOpen4, setIsOpen4] = useState(false)
  // const [isOpen5, setIsOpen5] = useState(false)

  // const validateOpen = (elementId: number) => {
  //   if (elementId === 1) {
  //     setIsOpen1(true)
  //     setIsOpen2(false)
  //     setIsOpen3(false)
  //     setIsOpen4(false)
  //     setIsOpen5(false)
  //   } else if (elementId === 2) {
  //     setIsOpen1(false)
  //     setIsOpen2(true)
  //     setIsOpen3(false)
  //     setIsOpen4(false)
  //     setIsOpen5(false)
  //   } else if (elementId === 3) {
  //     setIsOpen1(false)
  //     setIsOpen2(false)
  //     setIsOpen3(true)
  //     setIsOpen4(false)
  //     setIsOpen5(false)
  //   } else if (elementId === 4) {
  //     setIsOpen1(false)
  //     setIsOpen2(false)
  //     setIsOpen3(false)
  //     setIsOpen4(true)
  //     setIsOpen5(false)
  //   } else if (elementId === 5) {
  //     setIsOpen1(false)
  //     setIsOpen2(false)
  //     setIsOpen3(false)
  //     setIsOpen4(false)
  //     setIsOpen5(true)
  //   }
  // }

  const selector = () => {
    // if(mapData === 'southAmerica'){
      return (
        <div>
          <div className='country-card'>
            <img src={colombiaCircle} alt="colombia" />
            <h3>Colombia</h3>
            <h4>85,295</h4>
          </div>
          <div className='country-card'>
            <img src={ecuadorCircle} alt="ecuador" />
            <h3>Ecuador</h3>
            <h4>36,271</h4>
          </div>
          <div className='country-card'>
            <img src={peruCircle} alt="peru" />
            <h3>Perú</h3>
            <h4>30,000</h4>
          </div>
          <div className='country-card'>
            <img src={chileCircle} alt="chile" />
            <h3>Chile</h3>
            <h4>22,000</h4>
          </div>
          <div className='country-card'>
            <img src={mexCircle} alt="méxico" />
            <h3>México</h3>
            <h4>17,780</h4>
          </div>
        </div> 
      )
    // }
    // else if(mapData === 'northAmerica'){
    //   return(
    //     <div>
    //       <div className='country-card'>
    //         <img src={mexCircle} alt="méxico" />
    //         <h3>México</h3>
    //         <h4>17,780</h4>
    //       </div>
    //     </div>
    //   )
    // }
  }

  return (
    <div>
      {selector()}
    </div>
  )
}

export default AccordionCards