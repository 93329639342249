import React from 'react'
import { FormattedMessage } from 'react-intl'
import step1 from './assets/images/step1.svg'
import step2 from './assets/images/step2.svg'
import step3 from './assets/images/step3.svg'
import step4 from './assets/images/step4.svg'
import './styles.scss'


const CashSteps = () => {

  const steps = [
    {
      title: 'cash_landing.steps.step1.title',
      description: 'cash_landing.steps.step1.description',
      step: 'cash_landing.steps.step1.step',
      image: step1
    },
    {
      title: 'cash_landing.steps.step2.title',
      description: 'cash_landing.steps.step2.description',
      step: 'cash_landing.steps.step2.step',
      image: step2
    },
    {
      title: 'cash_landing.steps.step3.title',
      description: 'cash_landing.steps.step3.description',
      step: 'cash_landing.steps.step3.step',
      image: step3
    },
    {
      title: 'cash_landing.steps.step4.title',
      description: 'cash_landing.steps.step4.description',
      step: 'cash_landing.steps.step4.step',
      image: step4
    },
  ]

  return (
    <section className="container steps">
      <div className='mb-7'>
        <h2 className="text-primary" ><FormattedMessage id='cash_landing.steps.title'/></h2>
        <p><FormattedMessage id='cash_landing.steps.description'/></p>
      </div>
      <div className="row">
        {
          steps.map((step, index) => (
            <div className="col-12 col-md-4 col-lg-3" key={index}>
              <div className="cash-step">
                <div className="step_image d-flex justify-content-center">
                  <img src={step.image} />
                </div>
                <span className='step-step'>
                  <FormattedMessage id={step.step} />
                </span>
                <h4 className="text-primary mt-2" >
                  <FormattedMessage id={step.title} />
                </h4>
                <p className='mb-7 mt-4'>
                  <FormattedMessage id={step.description} />
                </p>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default CashSteps