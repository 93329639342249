import React, {useState} from 'react'
import CashMap from './CashMap';
import CashMapButtons from './CashMapButtons';
import CashAccordeon from './CashAccordeon';
import { FormattedMessage } from 'react-intl'
import america from './assets/images/america.svg'
import { defaultFormatValues } from '../../../data/defaultFormat';
import './styles.scss'


const CashSalesPoints: React.FC = () => {
  const [tab, setTab] = useState('')

  const northAmClick = (e: any) => {
    e.preventDefault()
    setTab('northAmerica')
  }

  const southAmClick = (e: any) => {
    e.preventDefault()
    setTab('southAmerica')
  }
  
  return (
    <>
      <div className="container cash-map-container">
        <div className="row">
          <div className="content-cash-map col-md-6 col-lg-6">
            <h2 className="content-cash-map-title"><FormattedMessage id="cash_landing.salespoints.title" values={defaultFormatValues} /></h2>
            <p className="content-cash-map-paragraph"><FormattedMessage id="cash_landing.salespoints.description" values={defaultFormatValues} /></p>
            <div className="zone-buttons">
              {/* <button className="zone-button" onClick={northAmClick}><FormattedMessage id="cash_landing.salespoints.northamerica" values={defaultFormatValues} /></button>
              <button className="zone-button" onClick={southAmClick}><FormattedMessage id="cash_landing.salespoints.southamerica" values={defaultFormatValues} /></button><br /> */}
            </div>
            <div>
              <CashMapButtons mapData={tab}/>
            </div>
          </div>
          <div className="map col-md-6 col-lg-6">
            <img src={america} alt="" className="america-map"/>
          </div> 
        </div> 
      </div>
      <div className="container accordeon-container">
        <CashAccordeon />
      </div>
    </>
    );
  };
      
export default CashSalesPoints;
      