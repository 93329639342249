import React, {useState} from 'react'
import CashPoints from './CashPoints';

import ecuador from './assets/images/ecuador.svg';
import colombia from './assets/images/colombia.svg';
import chile from './assets/images/chile.svg';
import peru from './assets/images/peru.svg';
import mexico from './assets/images/mexico.svg';

interface CashMapButtonsProps{
  mapData: string
}

const CashMapButtons: React.FC<CashMapButtonsProps> = ({mapData}) => {
  const [country, setCountry] = useState('')

  const colombiaClick = (e: any) => {
    e.preventDefault()
    setCountry('colombia')
  }

  const ecuadorClick = (e: any) => {
    e.preventDefault()
    setCountry('ecuador')
  }

  const chileClick = (e: any) => {
    e.preventDefault()
    setCountry('chile')
  }

  const peruClick = (e: any) => {
    e.preventDefault()
    setCountry('peru')
  }

  const mexicoClick = (e: any) => {
    e.preventDefault()
    setCountry('mexico')
  }

  const buttonSelector = () => {
    // if(mapData === 'southAmerica'){
      return (
        <>
        <div className="country-buttons">
          <button className='country-button' onClick={ecuadorClick} ><img src={ecuador} alt="Ecuador" />Ecuador</button>
          <button className='country-button' onClick={colombiaClick}><img src={colombia} alt="Colombia" /> Colombia</button>
          <button className='country-button' onClick={chileClick}><img src={chile} alt="Chile" />Chile</button>
          <button className='country-button' onClick={peruClick}><img src={peru} alt="Perú" />Perú</button>
          <button className='country-button-na' onClick={mexicoClick}><img src={mexico} alt="México" />México</button>  
        </div> 
        <CashPoints country={country} mapData={mapData}/>
        </>
      )
    // }
    // else if(mapData === 'northAmerica'){
    //   return(
    //     <>
    //     <div className="country-buttons">
    //       <button className='country-button-na' onClick={mexicoClick}><img src={mexico} alt="México" />México</button>
    //     </div> 
    //     <CashPoints country={country} mapData={mapData}/>
    //     </>
    //   )
    // } else {
    //   return (
    //     <div></div> 
    //   )
    // }
  }

  return (
    <>
      {buttonSelector()}
    </>
  )
}

export default CashMapButtons