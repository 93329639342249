import React, {useState} from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat';
import AccordionCards from './AccordionCards';

const CashAccordeon: React.FC = () => {

  const [tab, setTab] = useState('')

  return (
    <div>
      <div className="cash-responsive-desc mt-4">
        <h2 className="content-cash-map-title"><FormattedMessage id="cash_landing.salespoints.title" values={defaultFormatValues} /></h2>
        <p className="content-cash-map-paragraph"><FormattedMessage id="cash_landing.salespoints.description" values={defaultFormatValues} /></p>
      </div>
      <div className="zone-buttons">
      </div>
      <AccordionCards mapData={tab || "southAmerica"} />
    </div>
  )
}

export default CashAccordeon