import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat';
import SalesButton from '../../SalesButton/SalesButton';
import HeroImg from './assets/images/hero.inline.svg'
import './styles.scss'

const CashHero: React.FC = () => {
  return (
    <>
      <section className="cash-hero hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="cash-hero-title">
                <FormattedMessage id="cash_landing.hero.title" />
              </h2>
              <p className="cash-hero-description">
                <FormattedMessage id="cash_landing.hero.description" values={defaultFormatValues} />
              </p>
              <SalesButton className='cash-hero-salesButton'>
                <FormattedMessage id="buttons.sales" />
              </SalesButton>
            </div>
            <div className="col-md-6">
              <HeroImg className="cash-hero__img" />
            </div>
          </div>
        </div>
      </section>
      <div className="triangle"></div> 
    </>
  )
}

export default CashHero