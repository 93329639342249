import React from 'react'
import CashBenefits from '../../components/CashLanding/CashBenefits/CashBenefits'
import CashHero from '../../components/CashLanding/CashHero/CashHero'
import CashBanner from '../../components/CashLanding/CashBanner/CashBanner';
import CashSteps from '../../components/CashLanding/CashSteps/CashSteps';
import Footer from '../../components/Footer/Footer';
import { LocaleTypes } from '../../data/locales';
import IndexLayout from '../../layouts'
import CashSalesPoints from '../../components/CashLanding/CashMap/CashSalesPoints';
import CashLinks from '../../components/CashLanding/CashLinks/CashLinks';
import CashRocket from '../../components/CashLanding/CashRocket/CashRocket';
import CashCarrousel from '../../components/CashLanding/CashCarrousel/CashCarrousel';

interface AboutUsPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const cash: React.FC<AboutUsPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <CashHero />

      <CashSalesPoints />
      
      <CashBenefits />

      <CashCarrousel />

      <CashSteps />

      <CashBanner />

      <CashLinks />

      <CashRocket />

      <Footer theme="primary" />

    </>
  )



  return (
    <IndexLayout render={ content } locale={pathContext.localeCode} navbarTheme="default" location={location}  />
    )
  }
  
  export default cash
