import React from 'react'
import { FormattedMessage } from 'react-intl'
import Educacion from './assets/images/carrousel1.svg'
import Ecommerce from './assets/images/carrousel2.svg'
import Seguros from './assets/images/carrousel3.svg'
import Apps from './assets/images/carrousel4.svg'
import Servicios from './assets/images/carrousel5.svg'
import Delivery from './assets/images/carrousel6.svg'
import './styles.scss'

const cardContent = [
  {
    title: 'cash_landing.carrousel.card1.title',
    description: 'cash_landing.carrousel.card1.content',
    image: Apps
  },{
    title: 'cash_landing.carrousel.card2.title',
    description: 'cash_landing.carrousel.card2.content',
    image: Delivery
  },{
    title: 'cash_landing.carrousel.card3.title',
    description: 'cash_landing.carrousel.card3.content',
    image: Ecommerce
  },{
    title: 'cash_landing.carrousel.card4.title',
    description: 'cash_landing.carrousel.card4.content',
    image: Seguros
  },{
    title: 'cash_landing.carrousel.card5.title',
    description: 'cash_landing.carrousel.card5.content',
    image: Educacion
  },{
    title: 'cash_landing.carrousel.card6.title',
    description: 'cash_landing.carrousel.card6.content',
    image: Servicios
  }
]

const CashCarrouselCard:React.FC = () => {
  return (
    <div className="cash-carrousel-cards">
      {
        cardContent.map((item, index) => {
          return (
            <div key={index} className="card cash-carrousel-card">
              <div className="cash-carrousel-image">
                <img src={item.image} alt={item.title} className="cash-carrousel-card-image"/>
              </div>
              <h4 className="card-title text-center text-primary mt-2"><FormattedMessage id={item.title} /></h4>
              <p className="card-content mt-4"><FormattedMessage id={item.description} /></p>
            </div>
          )
        })
      }
    </div>
  )
}

export default CashCarrouselCard